<template>
  <v-container fluid>
    <v-row>
      <h4 class="title">Support ticket</h4>
    </v-row>
    <v-row v-if="ticket">
      <v-col cols="12" sm="10">
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="8" class="title">
                From: {{ ticket.email }}
              </v-col>
              <v-col cols="12" sm="4"
                >{{ ticket.createdTime | fromNow }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" class="title">
                Name: {{ ticket.profile ? ticket.profile.name : ticket.name }}

                <span class="red--text" v-if="!ticket.profile"
                  >Not registered in TakeIn</span
                >
              </v-col>

              <v-col cols="12" sm="4">
                Phone: {{ ticket.profile ? ticket.profile.phone : null }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            Message:
            <cite>
              {{ ticket.message }}
            </cite>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="info"
              v-if="ticket.profile"
              :to="'/messages/new?uid=' + ticket.profile.id"
              >Reply
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn to="/admin/support/list"> back </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col sm="6">
        <v-alert border color="error" outlined>Not found</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      ticket: null,
      reply: null
    }
  },
  mounted() {
    if (this.$route.params.id) {
      console.log('Loading support:', this.$route.params.id)
      this.$store
        .dispatch('loadSupportTicket', { id: this.$route.params.id })
        .then(ticket => {
          this.ticket = ticket
        })
    }
  }
}
</script>
